const config = {
  "apigateway_key": "c3RjZGFUc1NGelZtMlNVTXlxcGtMYVl3Mg==",
  "version": "1.0.202211180840",
  "application_name": "Herotel FCT",
  "serverUrl": "https://coverage.herotel.com:8096",
  "menuExclusionsList": [
    "/authentication/login"
    , "/authentication/reset-password"
  ],
  "authentication": {
    "defaultUserRegistrationGroup": "1"
  },
  "refresh_interval": 30000
}

export { config }
//http://13.245.156.220:8082