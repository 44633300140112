import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./authentication/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'authentication/login',
    loadChildren: () => import('./authentication/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'samples/dashboards',
    loadChildren: () => import('./samples/dashboards/dashboards.module').then( m => m.DashboardsPageModule)
  },
  {
    path: 'samples/forms',
    loadChildren: () => import('./samples/forms/forms.module').then( m => m.FormsPageModule)
  },
  {
    path: 'samples/visuals',
    loadChildren: () => import('./samples/visuals/visuals.module').then( m => m.VisualsPageModule)
  },
  {
    path: 'administrator/users',
    loadChildren: () => import('./administrator/users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'administrator/activity-reports',
    loadChildren: () => import('./administrator/activity-reports/activity-reports.module').then( m => m.ActivityReportsPageModule)
  },
  {
    path: 'authentication/manage-my-profile',
    loadChildren: () => import('./authentication/manage-my-profile/manage-my-profile.module').then( m => m.ManageMyProfilePageModule)
  },
  {
    path: 'administrator/manage-user-profile',
    loadChildren: () => import('./administrator/manage-user-profile/manage-user-profile.module').then( m => m.ManageUserProfilePageModule)
  },
  {
    path: 'administrator/group-management',
    loadChildren: () => import('./administrator/group-management/group-management.module').then( m => m.GroupManagementPageModule)
  },
  {
    path: 'administrator/group-management-profile',
    loadChildren: () => import('./administrator/group-management-profile/group-management-profile.module').then( m => m.GroupManagementProfilePageModule)
  },
  {
    path: 'administrator/api-activity-reports',
    loadChildren: () => import('./administrator/api-activity-reports/api-activity-reports.module').then( m => m.ApiActivityReportsPageModule)
  },
  {
    path: 'bulk-feasibility',
    loadChildren: () => import('./bulk-feasibility/bulk-feasibility.module').then( m => m.BulkFeasibilityPageModule)
  },
  {
    path: 'test',
    loadChildren: () => import('./test/test.module').then( m => m.TestPageModule)
  },  {
    path: 'network-visualizer',
    loadChildren: () => import('./network-visualizer/network-visualizer.module').then( m => m.NetworkVisualizerPageModule)
  }


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
