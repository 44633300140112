import { Component } from '@angular/core';
import { Event, RouterEvent, Router } from '@angular/router';
import { config } from '../config';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilitiesFunctions } from '../utilities';
import { AlertController, LoadingController, MenuController } from '@ionic/angular';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Clipboard } from '@ionic-native/clipboard/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  document_display_sign: boolean = false;
  EUA_Document: SafeResourceUrl;
  EUA_sign_display: boolean = false;
  PP_Document: SafeResourceUrl;
  PP_sign_display: boolean = false;
  TNC_Document: SafeResourceUrl;
  TNC_sign_display: boolean = false;
  users_groups: any = [];
  selected_group: number;
  group_url: string = config["serverUrl"] + "/XModule/utilities/get_file?filename=";

  exclude_nav_menu: boolean = true;
  overlayHidden: boolean = true;
  session_lock_otp_submitted: boolean = false;
  session_lock_otp_pin: string = "";
  action_detected: boolean = true;
  session_lock_display: boolean = false;
  document_sign_display: boolean = false;
  test: boolean = false;

  is_administrator: boolean = false;
  user_information: any = {};
  system_configuration: any = {};

  constructor(
    private router: Router
    , private storage: Storage
    , public http: HttpClient
    , public utilitiesFunctions: UtilitiesFunctions
    , public loadingCtrl: LoadingController
    , public sanitizer: DomSanitizer
    , public menuCtrl: MenuController
    , private clipboard: Clipboard
  ) {
    setTimeout(() => { this.menuCtrl.enable(false); }, 1);
    setInterval(data => {
      this.authenticate_session();
    }, config["refresh_interval"]);
    // this.module_load()
  }

  copyString() {
    this.clipboard.copy("Testing");
    alert('string copied');
  }

  ngOnInit() {
    if (this.router.url == '/authentication/login') {
      this.storage.clear();
    }
    this.module_load();
  }

  select_group(id) {
    this.selected_group = id;
  }

  open_documentation() {
    this.storage.get('SessionToken').then((SessionToken) => {
      if (SessionToken != null) {
        window.open(config["serverUrl"] + "?access-token=" + SessionToken, '_blank');
      }
    });
  }

  validate_administrator_token() {
    this.storage.get('SessionToken').then((SessionToken) => {
      if (SessionToken != null) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('access-token', SessionToken);

        this.http.get(config["serverUrl"] + "/XModule/administrator/validate_admin_session_token", { headers: headers })
          .subscribe(data => {
            console.log(JSON.stringify(data));
            this.is_administrator = true;
          }, error => {
            console.log(JSON.stringify(error));
          });
      }
    });
  }

  module_load() {
    this.storage.get('SessionToken').then((SessionToken) => {
      if (SessionToken != null) {
        console.log('Incluing nav menu');
        // this.exclude_nav_menu = false;
        this.menuCtrl.enable(true);
        this.storage.get('user_information').then((user_information) => {
          this.user_information = user_information;
        });

        this.storage.get('user_groups').then((user_groups) => {
          this.users_groups = user_groups;
          if (this.selected_group == null) {
            this.selected_group = this.users_groups[0]["id"];
          }
        });

        this.storage.get('system_configuration').then((system_configuration) => {
          this.system_configuration = system_configuration;
        });

        this.EUA_Document = this.sanitizer.bypassSecurityTrustResourceUrl(config['serverUrl'] + "/documents/EUA");
        this.PP_Document = this.sanitizer.bypassSecurityTrustResourceUrl(config['serverUrl'] + "/documents/PP");
        this.TNC_Document = this.sanitizer.bypassSecurityTrustResourceUrl(config['serverUrl'] + "/documents/TNC");
        this.check_documents();
      } else {
        console.log('Excluding nav menu');
        this.menuCtrl.enable(false);
        // this.exclude_nav_menu = true;
      }
    });

    this.authenticate_session();
  }

  sign_document(document_type) {
    this.loadingCtrl.create({
      message: 'Loading...',
      spinner: 'circles',
    }).then(lc => {
      lc.present();

      this.storage.get('SessionToken').then((SessionToken) => {
        if (SessionToken != null) {
          let headers = new HttpHeaders();
          headers = headers.set('Content-Type', 'application/json');
          headers = headers.set('access-token', SessionToken);
          headers = headers.set('agreement_label', document_type);

          this.http.get(config["serverUrl"] + "/XModule/authentication/sign_system_agreement", { headers: headers })
            .subscribe(data => {
              console.log(JSON.stringify(data));
              console.log('Session valid');
              var _response = JSON.parse('[' + JSON.stringify(data) + ']');
              this.utilitiesFunctions.toast_alert(_response[0]["Message"], 3000, 'top');
              lc.dismiss();
              this.check_documents();
            }, error => {
              console.log(JSON.stringify(error));
              console.log('Session Invalid...');
              var _response = JSON.parse('[' + JSON.stringify(error.error) + ']')[0];
              this.utilitiesFunctions.toast_alert(_response["Message"], 3000, 'top');
              lc.dismiss();
            });
        } else {
          lc.dismiss();
        }
      });
    });
  }

  check_documents() {
    this.loadingCtrl.create({
      message: 'Loading...',
      spinner: 'circles',
    }).then(lc => {
      lc.present();

      this.EUA_sign_display = false;
      this.TNC_sign_display = false;
      this.PP_sign_display = false;
      this.overlayHidden = true;
      this.document_display_sign = false;
      this.storage.get('SessionToken').then((SessionToken) => {
        if (SessionToken != null) {
          let headers = new HttpHeaders();
          headers = headers.set('Content-Type', 'application/json');
          headers = headers.set('access-token', SessionToken);

          this.http.get(config["serverUrl"] + "/XModule/authentication/get_session_user_information", { headers: headers })
            .subscribe(data => {
              var _response = JSON.parse('[' + JSON.stringify(data) + ']');
              this.storage.set('user_information', _response[0]["UserInformation"]);
              this.storage.set('user_groups', _response[0]["UserGroups"]);
              this.storage.get('system_configuration').then((configurations) => {
                if (configurations != null) {
                  this.storage.get('user_information').then((user_information) => {
                    this.validate_administrator_token();
                    if (user_information != null) {
                      // if (configurations['authentication']['require_end_user_agreement_signature'] == true && user_information["signed_eua"] == 0) {
                      //   this.EUA_sign_display = true;
                      //   this.document_display_sign = true;
                      //   this.overlayHidden = false;
                      // } else if (configurations['authentication']['require_privacy_policy_signature'] == true && user_information["signed_pp"] == 0) {
                      //   this.PP_sign_display = true;
                      //   this.document_display_sign = true;
                      //   this.overlayHidden = false;
                      // } else if (configurations['authentication']['require_terms_and_conditions_signature'] == true && user_information["signed_tnc"] == 0) {
                      //   this.TNC_sign_display = true;
                      //   this.document_display_sign = true;
                      //   this.overlayHidden = false;
                      // }
                      lc.dismiss();
                    } else {
                      lc.dismiss();
                    }
                  })
                } else {
                  lc.dismiss();
                }
              })
            }, error => {
              var _response = JSON.parse('[' + JSON.stringify(error.error) + ']')[0];
              this.utilitiesFunctions.toast_alert(_response["Message"], 3000, 'top');
              lc.dismiss();
            });
        } else {
          lc.dismiss();
        }
      });
    });
  }

  keypresss(val) {
    console.log(val);
  }

  session_lock_generate_otp() {
    this.loadingCtrl.create({
      message: 'Loading...',
      spinner: 'circles',
    }).then(lc => {
      lc.present();

      this.storage.get('SessionToken').then((value) => {
        if (value == null) {
          value = "-";
        }

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('access-token', value);

        this.http.get(config["serverUrl"] + "/XModule/authentication/generate_session_lock_otp", { headers: headers })
          .subscribe(data => {
            console.log(JSON.stringify(data));
            console.log('Session valid');
            var _response = JSON.parse('[' + JSON.stringify(data) + ']');
            this.utilitiesFunctions.toast_alert(_response[0]["Message"], 3000, 'top');
            this.session_lock_otp_submitted = true;
            lc.dismiss();
          }, error => {
            console.log(JSON.stringify(error));
            console.log('Session Invalid...');
            var _response = JSON.parse('[' + JSON.stringify(error.error) + ']')[0];
            this.utilitiesFunctions.toast_alert(_response["Message"], 3000, 'top');
            lc.dismiss();
          });
      });
    });
  }

  session_submit_unlock_otp() {
    this.loadingCtrl.create({
      message: 'Loading...',
      spinner: 'circles',
    }).then(lc => {
      lc.present();

      this.storage.get('SessionToken').then((value) => {
        if (value == null) {
          value = "-";
        }

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('access-token', value);
        headers = headers.set('code', this.session_lock_otp_pin);
        // alert(JSON.stringify(headers));

        this.http.get(config["serverUrl"] + "/XModule/authentication/unlock_session_with_otp", { headers: headers })
          .subscribe(data => {
            console.log(JSON.stringify(data));
            console.log('Session valid');
            var _response = JSON.parse('[' + JSON.stringify(data) + ']');
            this.utilitiesFunctions.toast_alert(_response[0]["Message"], 3000, 'top');
            this.session_lock_otp_submitted = false;
            this.overlayHidden = true;
            this.session_lock_display = false;
            lc.dismiss();
          }, error => {
            console.log(JSON.stringify(error));
            console.log('Session Invalid...');
            var _response = JSON.parse('[' + JSON.stringify(error.error) + ']')[0];
            this.utilitiesFunctions.toast_alert(_response["Message"], 3000, 'top');
            if (_response["Message"] == 'Your session has expired') {
              this.logout();
            }
            lc.dismiss();
          });
      });
    });
  }

  logout() {
    this.storage.remove('SessionToken');
    window.location.replace('/authentication/login');
  }

  authenticate_session() {
    console.log('Checking session...');
    this.storage.get('SessionToken').then((value) => {
      if (value == null) {
        value = "-";
      }
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json');
      headers = headers.set('access-token', value);

      this.http.get(config["serverUrl"] + "/XModule/authentication/validate_session_token", { headers: headers })
        .subscribe(data => {
          console.log(JSON.stringify(data));
          console.log('Session valid');
          this.log_session_activity(value);
        }, error => {
          console.log(JSON.stringify(error));
          console.log('Session Invalid...');
          var _response = JSON.parse('[' + JSON.stringify(error.error) + ']')[0];
          if (_response["Message"] == 'Your session is locked') {
            this.overlayHidden = false;
            this.session_lock_display = true;
          } else {
            if (error.status >= 400 && error.status <= 450) {
              if (this.router.url != '/authentication/login' && this.router.url != '\/' && this.router.url != '') {
                // alert(_response["Message"]);
                this.storage.clear();
                window.location.replace('/authentication/login');
              }
            }
          }
        });
    });
  }

  mark_action_detected() {
    this.action_detected = true;
    console.log("Action marked");
  }

  log_session_activity(SessionToken) {
    console.log("Started action logging");
    if (this.action_detected) {
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json');
      headers = headers.set('access-token', SessionToken);

      this.http.get(config["serverUrl"] + "/XModule/authentication/capture_session_activity_ping", { headers: headers })
        .subscribe(data => {
          console.log(JSON.stringify(data));
          console.log('Session activity successfully pinged');
          this.action_detected = false;
        }, error => {
          console.log(JSON.stringify(error));
          console.log('Session activity ping failed...');
        });
    }
  }
}
