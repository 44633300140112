
import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from './config';
import { ToastController, LoadingController } from '@ionic/angular';
import { Injectable } from '@angular/core';
// import { Storage } from '@ionic/storage';

@Injectable({
    providedIn: 'root'
  })

export class UtilitiesFunctions{
    constructor(
      public alertController: AlertController
      , private toastCtrl: ToastController
      , public http: HttpClient
      , public loadingCtrl: LoadingController
      // , private storage: Storage
      ) {}

    validate_email(email) 
    {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
      {
        return (true);
      } else {
        return (false);
      }
    }

    add_days(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }

    format_date(date){
      return date.toISOString().split('T')[0]
    }

    get_datetime_now(){
      let date_now = new Date()
      return date_now;
    }
  
    async toast_alert(message, duration, position) {
      const toast = await this.toastCtrl.create({
        message: message,
        duration: duration,
        position: position
      });

      toast.present();
    }

    async alert_prompt(header, message, true_label, false_label) {
        var response;
        const alert = await this.alertController.create({
          header: header,
          message: message,
          buttons: [{text: true_label, handler: () => {response = true}}, {text: false_label, handler: () => {response = false}}],
        });
    
        await alert.present();

        await alert.onDidDismiss().then(()=>{
          return response;
        });
    }
}